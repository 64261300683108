//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import OpenData from '@/views/fileStream/documentManage/weixin/openData'
import { init_wx } from '@/views/fileStream/documentManage/weixin/weixin'

import { corpDeptPage, syncBookMess, wx_org } from '@/api/modular/fileStream/user'

import { documentLablePage, getLablePersonPage } from '@/api/modular/fileStream/documentLabelManage'
import { sysDictTypeDropDown } from '@/api/modular/system/dictManage'
import { sysUserChangeStatus, sysUserDelete, sysUserExport, sysUserResetPwd } from '@/api/modular/system/userManage'
import { STable, XCard, XDown } from '@/components'
import { handleAppointEleHeight } from '@/utils/common.js'
import { Empty } from 'ant-design-vue'
import addForm from './addForm'
import editForm from './editForm'
import userOrgForm from './userOrgForm'
import userRoleForm from './userRoleForm'
export default {
    components: {
        XDown,
        XCard,
        STable,
        addForm,
        editForm,
        userRoleForm,
        userOrgForm,
        OpenData,
    },
    data() {
        return {
            tags: [],
            tagActive: 0,
            tabActive: '1',
            deptIds: {},
            // 查詢參數
            queryParam: {},
            // 表頭
            columns: [
                {
                    title: '姓名',
                    dataIndex: 'userId',
                    width: '150px',
                    scopedSlots: { customRender: 'userId' },
                },
                {
                    title: '賬號',
                    dataIndex: 'my_action',
                    scopedSlots: { customRender: 'my_action' },
                },
                {
                    title: '企業名稱',
                    dataIndex: 'corpName',
                },
                {
                    title: '部門名稱',
                    dataIndex: 'deptId',
                    scopedSlots: { customRender: 'departmentName' },
                },
            ],
            // 加載數據方法 必須為 Promise 對象
            loadData: (parameter) => {
                if (this.tabActive === '2') {
                    return getLablePersonPage(
                        Object.assign(parameter, { labelId: this.tagActiveRow.id }, this.queryParam)
                    ).then((res) => {
                        handleAppointEleHeight(
                            '#ygh-content-box',
                            ['.user-search-wrapper', '.s-table-tool', '.ant-alert-info'],
                            99,
                            res
                        )
                        return res.data
                    })
                }
                return corpDeptPage(Object.assign(parameter, this.deptIds, this.queryParam)).then((res) => {
                    handleAppointEleHeight(
                        '#ygh-content-box',
                        ['.user-search-wrapper', '.s-table-tool', '.ant-alert-info'],
                        99,
                        res
                    )
                    return res.data
                })
            },
            orgTree: [],
            selectedRowKeys: [],
            selectedRows: [],
            sexDictTypeDropDown: [],
            statusDictTypeDropDown: [],
            treeLoading: true,
            simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
            replaceFields: {},
            options: {
                alert: {
                    show: true,
                    clear: () => {
                        this.selectedRowKeys = []
                    },
                },
                rowSelection: {
                    selectedRowKeys: this.selectedRowKeys,
                    onChange: this.onSelectChange,
                },
            },
        }
    },
    created() {
        this.getTree()
        this.sysDictTypeDropDown()
        this.getTags()
    },
    mounted() {
        // handleAppointEleHeight('#ygh-content-box', ['.user-search-wrapper', '.s-table-tool', '.ant-alert-info'], 99)
    },
    methods: {
        changeActive(tag, tagIndex) {
            this.tagActive = tagIndex
            this.tagActiveRow = tag
            this.$refs.table.refresh()
        },
        syncBook() {
            syncBookMess()
                .then((res) => {
                    if (res.code === 200) {
                        this.$message.success('同步成功')
                        this.getTree()
                    } else {
                        this.$message.info(res.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        getTree() {
            /**
             * 獲取到機構樹，展開頂級下樹節點，考慮到後期數據量變大，不建議全部展開
             */
            wx_org(Object.assign(this.queryParam))
                .then((res) => {
                    this.treeLoading = false
                    if (!res.success) {
                        return
                    }
                    //
                    const mydata = Object.assign([], res.data)
                    function make_icon(data) {
                        data.forEach((element) => {
                            element.scopedSlots = { title: 'custom' }
                            element.children && element.children.length == 0
                                ? (element.slots = { icon: 'tag' })
                                : (element.slots = { icon: 'tags' })
                            if (element.children && element.children.length > 0) {
                                make_icon(element.children)
                            } else {
                                return
                            }
                        })
                    }
                    make_icon(mydata)
                    //
                    this.orgTree = mydata
                })
                .then((x) => {
                    init_wx()
                })
        },
        sexFilter(sex) {
            // eslint-disable-next-line eqeqeq
            const values = this.sexDictTypeDropDown.filter((item) => item.code == sex)
            if (values.length > 0) {
                return values[0].value
            }
        },
        statusFilter(status) {
            // eslint-disable-next-line eqeqeq
            const values = this.statusDictTypeDropDown.filter((item) => item.code == status)
            if (values.length > 0) {
                return values[0].value
            }
        },
        /**
         * 獲取字典數據
         */
        sysDictTypeDropDown(text) {
            sysDictTypeDropDown({ code: 'sex' }).then((res) => {
                this.sexDictTypeDropDown = res.data
            })
            sysDictTypeDropDown({ code: 'common_status' }).then((res) => {
                this.statusDictTypeDropDown = res.data
            })
        },
        /**
         * 修改用戶狀態
         */
        editUserStatus(code, record) {
            // eslint-disable-next-line no-unused-vars
            const status = 0
            // eslint-disable-next-line eqeqeq
            if (code == 0) {
                this.status = 1
                // eslint-disable-next-line eqeqeq
            } else if (code == 1) {
                this.status = 0
            }
            sysUserChangeStatus({ id: record.id, status: this.status }).then((res) => {
                if (res.success) {
                    this.$message.success('操作成功')
                    this.$refs.table.refresh()
                } else {
                    this.$message.error('操作失敗：' + res.message)
                }
            })
        },
        /**
         * 重置密碼
         */
        resetPwd(record) {
            sysUserResetPwd({ id: record.id }).then((res) => {
                if (res.success) {
                    this.$message.success('重置成功')
                    // this.$refs.table.refresh()
                } else {
                    this.$message.error('重置失敗：' + res.message)
                }
            })
        },
        reset() {
            this.queryParam = {}
            this.$refs.table.refresh()
        },
        /**
         * 單個刪除
         */
        singleDelete(record) {
            const param = [{ id: record.id }]
            this.sysUserDelete(param)
        },
        /**
         * 批量刪除
         */
        batchDelete() {
            const paramIds = this.selectedRowKeys.map((d) => {
                return { id: d }
            })
            this.sysUserDelete(paramIds)
        },
        /**
         * 刪除用戶
         */
        sysUserDelete(param) {
            sysUserDelete(param)
                .then((res) => {
                    if (res.success) {
                        this.$message.success('刪除成功')
                        this.$refs.table.refresh()
                    } else {
                        this.$message.error('刪除失敗：' + res.message)
                    }
                })
                .catch((err) => {
                    this.$message.error('刪除錯誤：' + err.message)
                })
        },
        /**
         * 批量導出
         */
        batchExport() {
            sysUserExport().then((res) => {
                this.$refs.batchExport.downloadfile(res)
            })
        },
        /**
         * 點擊左側機構樹查詢列表
         */
        handleClick(it) {
            this.deptIds = { deptId: it.id, corpId: it.corpId }
            this.$refs.table.refresh(true)
        },

        handleOk() {
            this.$refs.table.refresh()
        },
        onSelectChange(selectedRowKeys, selectedRows) {
            this.selectedRowKeys = selectedRowKeys
            this.selectedRows = selectedRows
        },
        callback(key) {
            this.tabActive = key
            this.$refs.table.refresh()
        },
        getTags() {
            documentLablePage().then((res) => {
                if (res.code === 200) {
                    this.tags = res.data.rows
                    this.tagActiveRow = res.data.rows.length > 0 ? res.data.rows[0] : {}
                } else {
                    this.$message.warning(res.message)
                }
            })
        },
    },
}
