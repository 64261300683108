//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { sysUserEdit, sysUserDetail } from '@/api/modular/system/userManage'
import { getOrgTree, getOrgList } from '@/api/modular/system/orgManage'
import { sysPosList } from '@/api/modular/system/posManage'
import moment from 'moment'
export default {
  data () {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      // 機構行樣式
      labelCol_JG: {
        xs: { span: 24 },
        sm: { span: 3 }
      },
      wrapperCol_JG: {
        xs: { span: 24 },
        sm: { span: 20 }
      },
      count: 1,
      columns: [
        {
          title: '附屬機構',
          dataIndex: 'extOrgId',
          width: '45%',
          scopedSlots: { customRender: 'extOrgId' }
        },
        {
          title: '附屬崗位',
          dataIndex: 'extPosId',
          width: '45%',
          scopedSlots: { customRender: 'extPosId' }
        },
        {
          title: '操作',
          key: 'action',
          scopedSlots: { customRender: 'operation' }
        }
      ],
      visible: false,
      confirmLoading: false,
      orgTree: [],
      orgList: [],
      posList: [],
      sysEmpParamExtList: [],
      memberLoading: false,
      form: this.$form.createForm(this),
      data: [],
      birthdayString: ''
    }
  },
  methods: {
    // 初始化方法
    edit (record) {
      this.confirmLoading = true
      this.visible = true
      this.getOrgData()
      this.getPosList()
      // 基本信息加人表單
      setTimeout(() => {
        this.form.setFieldsValue(
          {
            id: record.id,
            account: record.account,
            name: record.name,
            nickName: record.nickName,
            sex: record.sex,
            email: record.email,
            phone: record.phone,
            tel: record.tel
          }
        )
      }, 100)
      // 時間單獨處理
      if (record.birthday != null) {
        this.form.getFieldDecorator('birthday', { initialValue: moment(record.birthday, 'YYYY-MM-DD') })
      }
      this.birthdayString = moment(record.birthday).format('YYYY-MM-DD')
      // 職位信息加入表單
      this.getUserDetaile(record.id)
    },
    /**
     * 通過用戶ID查詢出用戶詳情，將職位信息填充
     * @param id
     */
    getUserDetaile (id) {
      sysUserDetail({ 'id': id }).then((res) => {
        const SysEmpInfo = res.data.sysEmpInfo
        const Positions = []
        SysEmpInfo.positions.forEach(item => {
          Positions.push(item.posId)
        })
        this.form.getFieldDecorator('sysEmpParam.orgName', { initialValue: SysEmpInfo.orgName })
        this.form.getFieldDecorator('sysEmpParam.posIdList', { initialValue: Positions })
        this.form.getFieldDecorator('sysEmpParam.jobNum', { initialValue: SysEmpInfo.jobNum })
        this.form.getFieldDecorator('sysEmpParam.orgId', { initialValue: SysEmpInfo.orgId })
        SysEmpInfo.extOrgPos.forEach(item => {
          const length = this.data.length
          this.data.push({
            key: length === 0 ? '1' : (parseInt(this.data[length - 1].key) + 1).toString(),
            extOrgId: item.orgId,
            extPosId: item.posId
          })
        })
        this.confirmLoading = false
      })
    },
    /**
     * 增行
     */
    newMember () {
      const length = this.data.length
      this.data.push({
        key: length === 0 ? '1' : (parseInt(this.data[length - 1].key) + 1).toString(),
        extOrgId: '',
        extPosId: ''
      })
    },
    /**
     * 刪除
     */
    remove (key) {
      const newData = this.data.filter(item => item.key !== key)
      this.data = newData
    },
    /**
     * 選擇子表單單項觸發
     */
    handleChange (value, key, column) {
      const newData = [...this.data]
      const target = newData.find(item => key === item.key)
      if (target) {
        target[column] = value
        this.data = newData
      }
    },
    /**
     * 獲取機構數據，並加載於表單中
     */
    getOrgData () {
      getOrgTree().then((res) => {
        this.orgTree = res.data
      })
      getOrgList().then((res) => {
        this.orgList = res.data
      })
    },
    /**
     * 獲取職位list列表
     */
    getPosList () {
      sysPosList().then((res) => {
        this.posList = res.data
      })
    },
    /**
     * 選擇樹機構，初始化機構名稱於表單中
     */
    initrOrgName (value) {
      this.form.getFieldDecorator('sysEmpParam.orgName', { initialValue: this.orgList.find(item => value === item.id).name })
    },
    /**
     * 子表單json重構
     */
    JsonReconsitution () {
      this.sysEmpParamExtList = []
      const newData = [...this.data]
      newData.forEach(item => {
        // eslint-disable-next-line eqeqeq
        if (item.extOrgId != '' & item.extPosId != '') {
          this.sysEmpParamExtList.push({ orgId: item.extOrgId, posId: item.extPosId })
        }
      })
    },
    /**
     * 日期需單獨轉換
     */
    onChange (date, dateString) {
      this.birthdayString = moment(date).format('YYYY-MM-DD')
    },
    handleSubmit () {
      const { form: { validateFields } } = this
      this.confirmLoading = true
      validateFields((errors, values) => {
        if (!errors) {
          this.JsonReconsitution()
          values.sysEmpParam['extIds'] = this.sysEmpParamExtList
          // eslint-disable-next-line eqeqeq
          if (this.birthdayString == 'Invalid date') {
            this.birthdayString = null
          }
          values.birthday = this.birthdayString
          sysUserEdit(values).then((res) => {
            if (res.success) {
              this.$message.success('編輯成功')
              this.confirmLoading = false
              this.$emit('ok', values)
              this.handleCancel()
            } else {
              this.$message.error('編輯失敗：' + res.message)
            }
          }).finally((res) => {
            this.confirmLoading = false
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleCancel () {
      this.form.resetFields()
      this.visible = false
      // 清理子表單中數據
      this.data = []
      // 清理時間
      this.birthdayString = ''
      this.form.getFieldDecorator('birthday', { initialValue: null })
    }
  }
}
