//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getOrgTree } from '@/api/modular/system/orgManage'
import { sysUserOwnData, sysUserGrantData } from '@/api/modular/system/userManage'

export default {
  data () {
    return {
      labelCol: {
        style: { 'padding-right': '20px' },
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 15 }
      },
      orgTreeData: [],
      expandedKeys: [],
      checkedKeys: [],
      visible: false,
      confirmLoading: false,
      formLoading: true,
      autoExpandParent: true,
      selectedKeys: [],
      userEntity: [],
      replaceFields: {
        key: 'id'
      },
      form: this.$form.createForm(this)
    }
  },

  methods: {
    // 初始化方法
    userOrg (record) {
      this.userEntity = record
      this.visible = true
      // 獲取機構樹
      this.getOrgTree()
      // 已關聯數據
      this.sysUserOwnData(this.userEntity)
    },

    /**
     * 獲取機構樹
     */
    getOrgTree () {
      this.formLoading = true
      getOrgTree().then((res) => {
         if (res.success) {
           this.orgTreeData = res.data
           // 默認展開
           this.orgTreeData.forEach(item => {
             this.expandedKeys.push(item.id)
           })
         }
      })
    },

    /**
     * 此用戶已有數據列表
     */
    sysUserOwnData (record) {
      sysUserOwnData({ id: record.id }).then((res) => {
        if (res.success) {
          this.checkedKeys = res.data
        }
        this.formLoading = false
      })
    },

    onExpand (expandedKeys) {
      this.expandedKeys = expandedKeys
      this.autoExpandParent = false
    },
    onCheck (checkedKeys) {
      this.checkedKeys = checkedKeys
    },
    onSelect (selectedKeys, info) {
      this.selectedKeys = selectedKeys
    },

    handleSubmit () {
      const { form: { validateFields } } = this
      this.confirmLoading = true
      validateFields((errors, values) => {
        if (!errors) {
          sysUserGrantData({ id: this.userEntity.id, grantOrgIdList: this.checkedKeys }).then((res) => {
            if (res.success) {
              this.$message.success('授權成功')
              this.confirmLoading = false
              this.$emit('ok', values)
              this.handleCancel()
            } else {
              this.$message.error('授權失敗：' + res.message)
            }
          }).finally((res) => {
            this.confirmLoading = false
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleCancel () {
      this.form.resetFields()
      // 清空已選擇的
      this.checkedKeys = []
      // 清空已展開的
      this.expandedKeys = []
      this.visible = false
    }
  }
}
