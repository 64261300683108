//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getRolePage } from '@/api/modular/system/roleManage'
import { sysUserOwnRole, sysUserGrantRole } from '@/api/modular/system/userManage'

const columns = [
  {
    title: '角色名稱',
    dataIndex: 'name'
  },
  {
    title: '唯一編碼',
    dataIndex: 'code'
  }
]

export default {
  name: 'UserRoleIndex',

  data () {
    return {
      columns,
      loadData: [],
      selectedRowKeys: [], // Check here to configure the default column
      loading: true,
      visible: false,
      confirmLoading: false,
      recordEntity: []
    }
  },
  computed: {
    hasSelected () {
      return this.selectedRowKeys.length > 0
    }
  },
  methods: {
    // 初始化方法
    userRole (record) {
      this.recordEntity = record
      this.visible = true
      // 加載已有數據
      this.sysUserOwnRole()
      // 獲取全部列表,無需分頁
      getRolePage().then((res) => {
        this.loadData = res.data.rows
      })
    },

    /**
     * 獲取用戶已有角色
     */
    sysUserOwnRole () {
      this.loading = true
      sysUserOwnRole({ id: this.recordEntity.id }).then((res) => {
        // 選中多選框
        this.selectedRowKeys = res.data
        this.loading = false
      })
    },

    onSelectChange (selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },

    handleSubmit () {
      // eslint-disable-next-line no-unused-expressions
      this.confirmLoading = false
      this.visible = false
      sysUserGrantRole({ id: this.recordEntity.id, grantRoleIdList: this.selectedRowKeys }).then((res) => {
             if (res.success) {
               this.$message.success('授權成功')
               this.confirmLoading = false
               this.$emit('ok', this.recordEntity)
               this.handleCancel()
             } else {
               this.$message.error('授權失敗：' + res.message)
             }
           }).finally((res) => {
             this.confirmLoading = false
           })
    },
    handleCancel () {
      this.recordEntity = []
      this.selectedRowKeys = []
      this.visible = false
    }
  }
}
